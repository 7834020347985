import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  width: 20px;
  margin-left: 10px;
`;

export const RightArrow = ({ onClick }) => (
  <Icon
    width="48"
    height="21"
    viewBox="0 0 48 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M-4.2526e-07 11.2712L43.9109 11.2712L37.9345 21L38.8152 21L48 10.5L38.8152 2.10682e-07L37.9345 2.4918e-07L43.9109 9.72881L-4.92679e-07 9.72881L-4.2526e-07 11.2712Z"
      fill="black"
    />
  </Icon>
);
