import React, { useMemo } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { Row, Col } from "styled-bootstrap-grid";
import { mergePrismicPreviewData } from "gatsby-source-prismic";
import ReactGA from "react-ga";

// Components
import { Mailchimp } from "../components/mailchimp/mailchimp";
import { RightArrow } from "../components/icons/right-arrow";

const Page = styled.div`
  margin: 96px auto 0 auto;
  // max-width: 1400px;

  @media (max-width: 767px) {
    margin: 75px 0 0 0;
  }

  & h2 {
    text-align: center;
    margin-bottom: calc(75px - 1em);

    @media (max-width: 767px) {
      margin-bottom: 25px;
    }
  }

  & .text-column {
    margin-bottom: calc(150px - 1em);

    @media (max-width: 767px) {
      margin-bottom: 50px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;

const DownloadButton = styled.div`
  & a {
    display: flex;
    align-items: center;

    font-family: "Spectral", "Times New Roman", serif;

    -webkit-appearance: none;

    border: 0;
    margin: 0 0 24px 0;

    position: relative;

    cursor: pointer;

    &:focus {
      outline: none;
    }

    transition: all 0 ease-in-out;

    &:hover {
      color: #c4c4c4;

      & svg path {
        fill: #c4c4c4;
      }
    }
  }
`;

// Returns true if we're in a browser, false otherwise. This will help guard
// against SSR issues when building the site.
const IS_BROWSER = typeof window !== "undefined";

const Guide = ({ data: staticData }) => {
  const data = useMemo(() => {
    // If we're not in a browser (i.e. we're in SSR) or preview data has not been
    // set, use the non-preview static data.
    if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData;

    return mergePrismicPreviewData({
      staticData,
      previewData: window.__PRISMIC_PREVIEW_DATA__,
    });
  }, [staticData]);

  return (
    <Page>
      <Helmet title="Teaching Pack – A Delicate Sight" />

      <Row justifyContent="around">
        <Col col={12} md={6}>
          <div className="text-column">
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: data.prismicTeachingPack.data.text.html,
              }}
            />

            <DownloadButton>
              <ReactGA.OutboundLink
                eventLabel="Activity Pack"
                to={data.prismicTeachingPack.data.teaching_pack.url}
                target="_blank"
                rel="noreferrer noopener"
                download
              >
                Download A Darkness Guide <RightArrow />
              </ReactGA.OutboundLink>
            </DownloadButton>
          </div>
        </Col>

        <Col col={12} md={6}>
          <div className="text-column">
            <Mailchimp tag={`Teaching Pack`} />
          </div>
        </Col>
      </Row>
    </Page>
  );
};

export const query = graphql`
  {
    prismicTeachingPack {
      data {
        text {
          html
        }
        teaching_pack {
          url
        }
      }
    }
  }
`;

export default Guide;
