import React, { useState } from "react";
import styled from "styled-components";

// Components
import { RightArrow } from "../icons/right-arrow";

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  box-sizing: content-box;

  & div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;

    width: 100%;
    border-bottom: 1px solid #000;

    margin: 0 0 30px 0;
  }

  & .address-container {
    min-width: 300px;
    width: calc(100% - 90px);

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  & input {
    width: calc(100% - 115px);

    padding: 0;
    margin: 0;
    background-color: transparent;
    -webkit-appearance: none;

    border: 0;

    &:focus {
      outline: none;
    }

    position: relative;
    line-height: 1;

    @media (max-width: 767px) {
      width: 100%;
    }

    @media (max-width: 600px) {
      font-size: 15px;
      line-height: 34px;
    }
  }

  & label {
    @media (max-width: 1000px) {
      font-size: 18px;
      line-height: 28px;
    }

    @media (max-width: 600px) {
      font-size: 15px;
      line-height: 22px;
    }
  }

  & button {
    @media (max-width: 1000px) {
      font-size: 18px;
      line-height: 28px;
    }

    @media (max-width: 600px) {
      font-size: 15px;
      line-height: 22px;
    }
  }
`;

const MailchimpFormContainer = styled.div`
  & input {
    ::-webkit-input-placeholder {
      font-family: "Spectral", "Times New Roman", serif;
      font-size: 12px;
      line-height: 15px;
    }
    ::-moz-placeholder {
      font-family: "Spectral", "Times New Roman", serif;
      font-size: 12px;
      line-height: 15px;
    }
    :-ms-input-placeholder {
      font-family: "Spectral", "Times New Roman", serif;
      font-size: 12px;
      line-height: 15px;
    }
    :-moz-placeholder {
      font-family: "Spectral", "Times New Roman", serif;
      font-size: 12px;
      line-height: 15px;
    }
    ::placeholder {
      font-family: "Spectral", "Times New Roman", serif;
      font-size: 12px;
      line-height: 15px;
    }
  }
`;

const ThankYouMessage = styled.div`
  position: relative;

  margin: 1em 0;

  & a {
    border-bottom: 2px solid #000;
  }

  font-size: 19px;
  line-height: 28px;
  letter-spacing: 0.06em;

  @media (max-width: 1000px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 600px) {
    font-size: 15px;
    line-height: 22px;
  }
`;

const SignUpButton = styled.button`
  display: flex;
  align-items: center;

  font-family: "Spectral", "Times New Roman", serif;

  -webkit-appearance: none;

  border: 0;
  margin: 0 0 24px 0;

  position: relative;

  cursor: pointer;

  &:focus {
    outline: none;
  }

  transition: all 0 ease-in-out;

  &:hover {
    color: #c4c4c4;

    & svg path {
      fill: #c4c4c4;
    }
  }
`;

const PrivacyMessage = styled.div`
  width: 100%;

  color: #333;

  font-family: "Spectral", "Times New Roman", serif;

  font-size: 14px;
  line-height: 21px;

  & a {
    text-decoration: underline;
    text-decoration-color: #818181 !important;
  }

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

async function userSubscribeToMailchimp(data) {
  const subscribeMailchimp = await fetch(`/api/subscribe`, {
    method: "POST",
    body: JSON.stringify({
      FNAME: data.FNAME,
      LNAME: data.LNAME,
      tag: data.tag,
      email: data.email,
    }),
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
  let getSubscriberData = await subscribeMailchimp;
  return getSubscriberData;
}

async function userUpdateMailchimp(data) {
  const updateMailchimpSubscriber = await fetch(`/api/update-subscriber`, {
    method: "PATCH",
    body: JSON.stringify({
      FNAME: data.FNAME,
      LNAME: data.LNAME,
      tag: data.tag,
      email: data.email,
    }),
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
  let getUpdateMailchimpSubscriber = await updateMailchimpSubscriber;
  return getUpdateMailchimpSubscriber;
}

async function userUpdateTagsMailchimp(data) {
  const updateMailchimpSubscriberTags = await fetch(
    `/api/update-subscriber-tags`,
    {
      method: "POST",
      body: JSON.stringify({
        email: data.email,
        tag: data.tag,
      }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }
  );
  let getUpdateMailchimpSubscriberTags = await updateMailchimpSubscriberTags;
  return getUpdateMailchimpSubscriberTags;
}

export const Mailchimp = ({ tag }) => {
  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const [values, setValues] = useState({
    FNAME: "",
    LNAME: "",
    email: "",
    tag: tag,
  });

  const [mailchimpMessage, setMailchimpMessage] = useState(null);

  async function submitMailchimpForm(e) {
    e.preventDefault();

    const signupUserResult = await userSubscribeToMailchimp(values);

    if (signupUserResult.status === 200) {
      setMailchimpMessage("Thank you for subscribing.");
      const updateUsersTags = await userUpdateTagsMailchimp(values);
    }

    if (signupUserResult.status === 400) {
      setMailchimpMessage("Thank you for subscribing.");
      const updateUserResult = await userUpdateMailchimp(values);
      const updateUsersTags = await userUpdateTagsMailchimp(values);
    }
  }

  return (
    <MailchimpFormContainer>
      <p>
        We are giving away 5 copies of the Delicate Sight book. It features new
        writing from Bernardine Evaristo, Max Porter, Don Paterson and Raymond
        Antrobus, plus each book will have a unique hand drawn cover by Sam
        Winston.
      </p>
      <p>
        For an update on when the exhibitions open and a chance to be one of the
        first to receive the book, please leave your details here.
      </p>
      {mailchimpMessage === null && (
        <>
          <form onSubmit={submitMailchimpForm}>
            <InputContainer>
              <div>
                <label htmlFor="FNAME">First Name *</label>
                <input
                  type="text"
                  onChange={handleInputChange}
                  value={values.FNAME}
                  name="FNAME"
                  required
                />
              </div>
              <div>
                <label htmlFor="LNAME">Last Name *</label>
                <input
                  type="text"
                  onChange={handleInputChange}
                  value={values.LNAME}
                  name="LNAME"
                  required
                />
              </div>
              <div>
                <label htmlFor="Email">Email *</label>
                <input
                  type="email"
                  onChange={handleInputChange}
                  value={values.email}
                  name="email"
                  required
                />
              </div>
              <SignUpButton
                type="submit"
                disabled={values.email === null && values.name === null}
              >
                Submit <RightArrow />
              </SignUpButton>
            </InputContainer>

            <PrivacyMessage>
              We use Mailchimp as our marketing platform. By clicking above to
              subscribe, you acknowledge that your information will be
              transferred to Mailchimp for processing. Learn more about
              Mailchimp's privacy practices{" "}
              <a
                href="https://mailchimp.com/legal/"
                target="blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </PrivacyMessage>
          </form>
        </>
      )}
      {mailchimpMessage !== null && (
        <ThankYouMessage>{mailchimpMessage}</ThankYouMessage>
      )}
    </MailchimpFormContainer>
  );
};
